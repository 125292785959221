import { DateTime } from 'luxon'

import { useForm } from '@campaignhub/react-hooks'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import usePriceList from '@hooks/usePriceList'
import type { BulkUpdatePriceListsParams } from '@redux/modules/priceList'

import type { ModalCallbacks } from '../index'

const defaultState = {
  direction: '',
  expiresAt: '',
  percentage: 0,
}

const requiredFields = [
  { key: 'direction' },
  {
    invalidMessage: 'Invalid Percentage',
    key: 'percentage',
    validation: (percentage: number, entityState: typeof defaultState) => {
      const { direction } = entityState

      switch (direction){
        case 'increase':
          return percentage > 0
        case 'decrease':
          return percentage > 0 && percentage < 100
        case 'undoIncrease':
          return percentage > 0
        case 'undoDecrease':
          return percentage > 0 && percentage < 100
        default:
          return false
      }
    },
  },
]

type HandleSubmitParams = {
  callbacks: {
    bulkUpdateFn: (bulkUpdateParams: BulkUpdatePriceListsParams) => Promise<object>,
    bulkUpdatePriceLists: ModalCallbacks['bulkUpdatePriceLists'],
    closeModal: () => void,
    deselectAll: () => void,
  },
  entityState: typeof defaultState,
  priceListIds: number[],
}

const mapEntityState = (entityState: typeof defaultState) => {
  const {
    direction,
    expiresAt,
    percentage,
  } = entityState

  switch (direction){
    case 'increase':
      return {
        expiresAt,
        isIncrease: true,
        percentage,
        isUndo: false,
      }
    case 'decrease':
      return {
        expiresAt,
        isIncrease: false,
        percentage,
        isUndo: false,
      }
    case 'undoIncrease':
      return {
        expiresAt,
        isIncrease: true,
        percentage,
        isUndo: true,
      }
    case 'undoDecrease':
      return {
        expiresAt,
        isIncrease: false,
        percentage,
        isUndo: true,
      }
    default:
      return {}
  }
}

const afterAction = (callbacks: HandleSubmitParams['callbacks']) => {
  const { closeModal, deselectAll } = callbacks

  closeModal()
  deselectAll()
}

const handleSubmit = (params: HandleSubmitParams) => {
  const {
    callbacks,
    callbacks: {
      bulkUpdateFn,
      bulkUpdatePriceLists,
    },
    entityState,
    priceListIds,
  } = params

  const mappedEntityState = mapEntityState(entityState)

  const bulkUpdatePayload = {
    callbacks: {
      action: bulkUpdateFn,
      afterAction: () => afterAction(callbacks),
    },
    entityParams: {
      ...mappedEntityState,
      priceListIds,
    },
    toastText: 'Price Lists Updated',
  }

  bulkUpdatePriceLists(bulkUpdatePayload)
}

type BulkPriceListsParams = {
  callbacks: {
    bulkUpdatePriceLists: (payload: HandleCallbackActionParams) => void,
    closeModal: () => void,
    deselectAll: () => void,
  },
}

const useBulkUpdatePriceLists = (params: BulkPriceListsParams) => {
  const { callbacks } = params

  const {
    callbacks: {
      bulkUpdatePriceLists,
    },
    updating,
  } = usePriceList()

  const {
    entityState,
    errors,
    handlers,
    saveEnabled,
  } = useForm(defaultState, { requiredFields, validateOn: 'change' }, [])

  console.log(entityState, 'entityState')

  const currentDateTime = DateTime.now().toFormat('yyyy-MM-dd')

  return {
    callbacks: {
      handleSubmit: (priceListIds: number[]) => handleSubmit({
        callbacks: {
          ...callbacks,
          bulkUpdateFn: bulkUpdatePriceLists,
        },
        entityState,
        priceListIds,
      }),
    },
    currentDateTime,
    entityState,
    errors,
    handlers,
    saveEnabled,
    updating,
  }
}

export default useBulkUpdatePriceLists
