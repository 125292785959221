import { DateTime } from 'luxon'

import { formatDate, generateRedirectUrl } from '@campaignhub/javascript-utils'

import {
  DashboardModule, Form,
} from '@campaignhub/suit-theme'

import { generateUrls, usePriceListForm } from '@hooks/usePriceList'

import EntitySelector from '@components/EntitySelector'

interface PriceListDetailsProps {
  priceListFormPayload: ReturnType<typeof usePriceListForm>,
}

const PriceListDetails = (props: PriceListDetailsProps) => {
  const {
    priceListFormPayload: {
      entityState: {
        expiresAt,
        id,
        isArchived,
        name,
        nextPriceListId,
      },
      errors,
      handlers,
      setEntityState,
    },
  } = props

  const { editPriceListUrl } = generateUrls({ id: nextPriceListId })

  const formattedDate = formatDate(expiresAt, 'ISO8601')

  const currentDateTime = DateTime.now().toFormat('yyyy-MM-dd')

  return (
    <DashboardModule title="Price List Details">
      <Form padding="large">
        <Form.Row>
          <Form.Field errorMessage={errors.name} label="* Name">
            <input
              name="name"
              type="text"
              value={name}
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="Archived" marginTop={['large', 0]}>
            <input
              disabled
              value={isArchived ? 'Yes' : 'No'}
              type="text"
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field label="Expiry Date">
            <input
              min={currentDateTime}
              name="expiresAt"
              type="date"
              value={formattedDate}
              {...handlers}
            />
          </Form.Field>

          <EntitySelector
            callbacks={{
              selectItem: priceList => setEntityState({
                nextPriceListId: priceList ? priceList.id : null,
              }),
            }}
            entityKey="priceLists"
            entityTitleKey="name"
            goToHref={`${editPriceListUrl}?redirect=${generateRedirectUrl()}`}
            label="Next Price List"
            marginTop={['large', 0]}
            searchRequestOptions={{ isArchived: false }}
            selectedIds={[id]}
            selectedItemId={nextPriceListId}
          />
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default PriceListDetails
