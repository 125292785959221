import Swal from 'sweetalert2'

import { stringToTitleCase } from '@campaignhub/javascript-utils'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import type { DeleteEntity } from '@redux/modules/types'

type ConfirmActionParams = {
  callbacks: {
    actionFn: DeleteEntity,
    afterAction?: Function,
    buildActionParams?: () => object[],
  },
  options?: {
    confirmButtonColor?: string,
    entityParams?: HandleCallbackActionParams['entityParams'],
    icon?: 'success' | 'warning',
    isBulkAction?: boolean,
    requestOptions?: HandleCallbackActionParams['requestOptions'],
    shouldRedirect?: HandleCallbackActionParams['shouldRedirect'],
  },
  strings: {
    actionString?: string,
    confirmString?: string,
    entityName: string,
    toastString?: string,
  },
}

export const confirmAction = (params: ConfirmActionParams) => {
  const { callbacks, options, strings } = params

  const { actionFn, ...restCallbacks } = callbacks
  const {
    actionString,
    confirmString = 'Are you sure?',
    entityName,
    toastString,
  } = strings

  const {
    confirmButtonColor = '#43AC6A',
    entityParams,
    icon = 'success',
    isBulkAction = false,
    requestOptions,
    shouldRedirect,
  } = options || {}

  const title = `${actionString} ${entityName}?`
  const toastText = `${entityName} ${toastString || `${actionString}d`}`
  const confirmButtonText = `Yes, ${actionString} ${isBulkAction ? 'them' : 'it'}.`
  const payload = {
    callbacks: {
      action: actionFn,
      ...restCallbacks,
    },
    entityParams,
    requestOptions,
    shouldRedirect,
    toastText: stringToTitleCase(toastText),
  }

  Swal
    .fire({
      confirmButtonColor,
      confirmButtonText,
      icon,
      showCancelButton: true,
      text: confirmString,
      title: stringToTitleCase(title),
    })
    .then(({ value }) => {
      if (value) {
        handleCallbackAction(payload)
      }
    })
}

type ConfirmDeleteParams = ConfirmActionParams & {
  callbacks: {
    deleteFn: DeleteEntity,
  },
  options?: {
    isBulkDelete?: boolean,
  },
  strings: {
    deleteString?: 'archive' | 'delete' | 'remove' | 'unapprove' | 'unassign',
  },
}

export const confirmDelete = (params: ConfirmDeleteParams) => {
  const { callbacks, options, strings } = params

  confirmAction({
    callbacks: {
      ...callbacks,
      actionFn: callbacks.deleteFn,
    },
    options: {
      ...options,
      confirmButtonColor: '#DD6B55',
      icon: 'warning',
      isBulkAction: options?.isBulkDelete,
    },
    strings: {
      ...strings,
      actionString: strings?.deleteString || 'delete',
    },
  })
}

