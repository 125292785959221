import { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlaneTop } from '@fortawesome/pro-light-svg-icons'

import { toggleArray } from '@campaignhub/javascript-utils'

import {
  Button, DashboardModule, ModuleBlankState,
} from '@campaignhub/suit-theme'

import filter from '@assets/filter.svg'

import handleCallbackAction from '@functions/handleCallbackAction'

import useAdQueue from '@hooks/useAdQueue'
import useAdQueues from '@hooks/useAdQueues'
import type { AdQueueFilters } from '@hooks/useAdQueues'

import FailedCampaignsTable from './FailedCampaignsTable'

type ToggleSelectedAdQueueIdsParams = {
  adQueueId: number,
  selectedAdQueueIds: number[],
  setSelectedAdQueueIds: React.Dispatch<React.SetStateAction<number[]>>,
}

const toggleSelectedAdQueueId = (params: ToggleSelectedAdQueueIdsParams) => {
  const { adQueueId, selectedAdQueueIds, setSelectedAdQueueIds } = params

  const updatedIds: number[] = toggleArray(selectedAdQueueIds, adQueueId)

  setSelectedAdQueueIds(updatedIds)

  return updatedIds
}

interface FailedCampaignsProps {
  pageFilters: AdQueueFilters,
}

const FailedCampaigns = (props: FailedCampaignsProps) => {
  const { pageFilters } = props

  const [selectedAdQueueIds, setSelectedAdQueueIds] = useState<number[]>([])

  const {
    callbacks: {
      bulkProcessAdQueues,
      bulkDeleteAndRequeueAdQueues,
    },
    creating,
  } = useAdQueue()

  const adQueuesPayload = useAdQueues({
    filters: {
      ...pageFilters,
    },
    performHttpRequests: true,
    requestOptions: {
      include: [
        'Campaign',
        'FacebookCampaign',
        'PendingGoogleCampaign'
      ]
    },
  })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAdQueues,
    filteredAdQueuesCount,
    hasFilteredAdQueues,
    loading,
  } = adQueuesPayload

  const filteredAdQueueIds = filteredAdQueues.map(adQueue => adQueue.id)

  const hasSelectedAllAdQueues = selectedAdQueueIds.length >= filteredAdQueuesCount

  const bulkProcessAdQueuesPayload = {
    callbacks: {
      action: bulkProcessAdQueues,
    },
    entityParams: selectedAdQueueIds,
    toastText: 'Ad Campaigns Queued',
  }

  const bulkDeleteAndRequeueAdQueuesPayload = {
    callbacks: {
      action: bulkDeleteAndRequeueAdQueues,
    },
    entityParams: selectedAdQueueIds,
    toastText: 'Ad Campaigns Re-queued',
  }

  return (
    <DashboardModule
      boxProps={{ borderBottom: loading && !hasFilteredAdQueues ? 'none' : '1px solid lineColor' }}
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Deploying Campaigns"
    >
      {!loading && !hasFilteredAdQueues && (
        <ModuleBlankState boxProps={{ height: 200 }} imageUrl={filter}>
          <ModuleBlankState.Title>No Failed Campaigns</ModuleBlankState.Title>

          <ModuleBlankState.Paragraph>
            Try changing your filters, or wait for more results
          </ModuleBlankState.Paragraph>
        </ModuleBlankState>
      )}

      {!loading && hasFilteredAdQueues && (
        <FailedCampaignsTable
          adQueues={filteredAdQueues}
          callbacks={{
            toggleAllAdQueues: () => setSelectedAdQueueIds(hasSelectedAllAdQueues ? [] : filteredAdQueueIds),
            toggleSelectedAdQueueId: (adQueueId: number) => toggleSelectedAdQueueId(
              { adQueueId, selectedAdQueueIds, setSelectedAdQueueIds },
            ),
          }}
          hasSelectedAllAdQueues={hasSelectedAllAdQueues}
          selectedAdQueueIds={selectedAdQueueIds}
        />
      )}

      <DashboardModule.LoadMoreFooter
        boxProps={{ borderTop: loading ? 'none' : '1px solid' }}
        callbacks={{ loadMore }}
        canLoadMore={canLoadMore}
        entityCount={filteredAdQueuesCount}
        loading={loading}
      >
        <div>
          <Button
            buttonStyle="secondaryCreate"
            icon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
            loading={creating}
            onClick={() => handleCallbackAction(bulkProcessAdQueuesPayload)}
            size={['medium', 'small']}
          >
            Resubmit Campaigns
          </Button>
        </div>
        
        <div>
          <Button
            buttonStyle="secondaryCreate"
            icon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
            loading={creating}
            onClick={() => {
              handleCallbackAction(bulkDeleteAndRequeueAdQueuesPayload); 
              setSelectedAdQueueIds([]);
            }}
            size={['medium', 'small']}
            marginLeft="medium"
          >
            Delete and Requeue Campaigns
          </Button>
        </div>
      </DashboardModule.LoadMoreFooter>
    </DashboardModule>
  )
}

export default FailedCampaigns
